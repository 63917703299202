import { css, SerializedStyles } from '@emotion/react';
import { Asset } from 'contentful';
import { ReactElement } from 'react';
import { DeviceSpec, getPicture, ImageFit } from '../../utils/helpers/image';
import { getImageFitStyles, ImageFitWrapper } from '../component-library/ImageFit';

const LandscapeImageStyles = css`
  display: none;
  @media (min-aspect-ratio: 4/3) {
    display: initial;
  }
`;

const PortraitImageStyles = css`
  @media (min-aspect-ratio: 4/3) {
    display: none;
  }
`;

function isAsset(image: unknown): image is Asset<'WITHOUT_UNRESOLVABLE_LINKS'> {
  return (image as Asset).fields !== undefined;
}

export type ImageProps = {
  asset?: Asset<'WITHOUT_UNRESOLVABLE_LINKS'> | OrientedImage;
  alt?: string;
  fit?: ImageFit;
  disableLazyStyles?: boolean;
} & ImageStyleProps &
  ({ fitWrapper?: false } | WrapperProps);

export type ImageStyleProps = {
  wrapperCss?: SerializedStyles;
  css?: SerializedStyles;
  className?: string;
  /**
   * specify what portion of the screen the image takes. For example if the image takes half of the screen, use 2
   */
  spans?: DeviceSpec;
  /**
   * specify what is the aspect ratio of the image. This is especially useful on mobile when the original image is landscape and should be rendered in portrait
   */
  aspectRatios?: DeviceSpec;
  maxWidth?: number | DeviceSpec;
  lazyLoad?: boolean;
};

type WrapperProps = {
  fitWrapper: true;
  deviceWidths?: DeviceSpec;
  aspectRatio?: number;
  responsiveAspectRatios?: DeviceSpec;
};

export type OrientedImage = {
  landscape?: Asset<'WITHOUT_UNRESOLVABLE_LINKS'>;
  portrait?: Asset<'WITHOUT_UNRESOLVABLE_LINKS'>;
};

// Contentful replaces their images api url with downloads, so we have to fix the url
// TODO: Remove when we don't have images >20MB in contentful
function setImagesUrl(url: string) {
  return url.replace('//downloads.ctfassets.net', '//images.ctfassets.net');
}

const getStyles = (props: ImageProps) => {
  if (props.fitWrapper) {
    return getImageFitStyles();
  }
  return { css: undefined, wrapperCss: undefined };
};

const Picture = (props: Parameters<typeof getPicture>[0] & ImageProps) => {
  const parsedProps = { ...props, url: setImagesUrl(props.url), alt: props.alt ?? '' };

  const Pic = getPicture(parsedProps);
  return <Pic {...parsedProps} />;
};

type OrientedPictureProps = Omit<ImageProps, 'asset'> & {
  image: OrientedImage;
  extraStyles: ReturnType<typeof getStyles>;
};

const OrientedPicture = (props: OrientedPictureProps) => {
  const { image, className, alt, spans, aspectRatios, fit, maxWidth, extraStyles, lazyLoad } =
    props;
  const commonProps = {
    className,
    aspectRatios,
    spans,
    fit,
    maxWidth,
    lazyLoad,
  };

  if (!image.landscape || !image.portrait) {
    const fallbackImage = image.landscape || image.portrait;
    if (!fallbackImage) {
      return null;
    }
    return (
      <Picture
        css={extraStyles.css}
        alt={fallbackImage.fields.description || fallbackImage.fields?.title || alt}
        fileName={fallbackImage.fields.file?.fileName}
        url={fallbackImage.fields.file?.url || ''}
        {...commonProps}
      />
    );
  }

  return (
    <>
      <Picture
        css={css([LandscapeImageStyles, extraStyles.css])}
        alt={image.landscape.fields.description || image.landscape.fields?.title || alt}
        fileName={image.landscape.fields.file?.fileName}
        url={image.landscape.fields.file?.url || ''}
        {...commonProps}
      />
      <Picture
        css={css([PortraitImageStyles, extraStyles.css])}
        alt={image.portrait.fields?.description || image.portrait.fields?.title || alt}
        fileName={image.portrait.fields.file?.fileName}
        url={image.portrait.fields.file?.url || ''}
        {...commonProps}
      />
    </>
  );
};

/**
 *
 * @deprecated this component assumes full contentful data is passed through props which is inefficient in term of data transfer (from server to client). Optimize props server-side and use the following component instead:
 * @link ../component-library/Image.tsx
 */
const Image = (props: ImageProps) => {
  const { asset, className, alt, spans, aspectRatios, fit, maxWidth, lazyLoad } = props;

  if (!asset) {
    return null;
  }

  const extraStyles = getStyles(props);

  let picture: ReactElement;

  if (isAsset(asset)) {
    picture = (
      <Picture
        className={className}
        css={extraStyles.css}
        alt={asset?.fields?.description || asset?.fields?.title || alt}
        fileName={asset.fields.file?.fileName}
        url={asset.fields.file?.url || ''}
        aspectRatios={aspectRatios}
        spans={spans}
        fit={fit}
        maxWidth={maxWidth}
        lazyLoad={lazyLoad}
      />
    );
  } else {
    picture = (
      <OrientedPicture {...props} image={asset} extraStyles={extraStyles} lazyLoad={lazyLoad} />
    );
  }

  return (
    <Wrapper {...props} wrapperCss={css([extraStyles.wrapperCss, props.wrapperCss])}>
      {picture}
    </Wrapper>
  );
};

const Wrapper = (props: ImageProps & { children: JSX.Element }) => {
  const { children } = props;
  if (props.fitWrapper) {
    const { deviceWidths, aspectRatio, responsiveAspectRatios, wrapperCss } = props;
    return (
      <ImageFitWrapper
        deviceWidths={deviceWidths}
        aspectRatio={aspectRatio}
        responsiveAspectRatios={responsiveAspectRatios}
        css={wrapperCss}
      >
        {children}
      </ImageFitWrapper>
    );
  }
  return <div css={props.wrapperCss}>{children}</div>;
};

export default Image;
