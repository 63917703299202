import { TypePageSection } from '../../../@types/generated';
import { captureException } from '../../utils/tracking/sentry/sentry';
import { ClientSectionProps, SECTION_MAP_RENDER } from './pageSections';

type SectionProps = {
  section: TypePageSection;
};

type Props = ClientSectionProps & {
  serverSideProps: Record<string, unknown>;
};

const PageSection = ({
  index,
  section,
  serverSideProps,
  floatingHeader,
  slug,
  categoryName,
}: Props) => {
  const Component = getComponentFromType({ section });
  if (!Component) {
    return null;
  }
  return (
    <Component
      slug={slug}
      categoryName={categoryName}
      section={section}
      serverSideProps={serverSideProps}
      index={index}
      floatingHeader={floatingHeader}
    />
  );
};

const getComponentFromType = ({ section }: SectionProps) => {
  const Component = section.fields.type && SECTION_MAP_RENDER[section.fields.type];
  if (!Component) {
    captureException(new Error(`No component found for section type ${section.fields.type}`));
    return;
  }
  return Component;
};

export default PageSection;
