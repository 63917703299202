import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import { TypeLandingPage, TypeMicrocopy, TypeMicrocopySet } from '../../../../@types/generated';
import { isTypeMicrocopySet } from '../../../../@types/generated/TypeMicrocopySet';
import { PageContext } from '../../../utils/helpers/next';

const ThankYouPageSmartMove = dynamic(() => import('../pages/thankYou/ThankYouPageSmartMove'));

type UniversalMicroconfigProps = {
  context?: PageContext;
  locale?: string;
};

type ClientMicroconfigProps = {
  slug: string;
} & UniversalMicroconfigProps;

type ServerMicroconfigProps = {
  page: TypeLandingPage;
} & UniversalMicroconfigProps;

type UnknownConfigMap = Record<string, unknown>;
type UnknownServerSideProps = Record<string, unknown> | null;

/**
 * has an issue with react-docgen-typescript => https://github.com/styleguidist/react-docgen-typescript/issues/342
 */
export type MicroconfigTemplatePage<
  C extends UnknownConfigMap[],
  S extends UnknownServerSideProps,
> = FunctionComponent<
  ClientMicroconfigProps & {
    serverSideProps: S;
  }
> & {
  getCustomServerSideProps?: (
    props: ServerMicroconfigProps & {
      configMaps: C;
    },
  ) => Promise<S>;
};

export const MICROCONFIG_TEMPLATE_PAGE_MAP_SERVER = {
  'thank-you-page-smart-move': () => import('../pages/thankYou/ThankYouPageSmartMove'),
} as unknown as Record<
  string,
  () => Promise<{ default: MicroconfigTemplatePage<UnknownConfigMap[], UnknownServerSideProps> }>
>;

const MICROCONFIGSET_TEMPLATE_PAGE_MAP_RENDER = {
  'thank-you-page-smart-move': ThankYouPageSmartMove,
} as unknown as Record<string, MicroconfigTemplatePage<UnknownConfigMap[], UnknownServerSideProps>>;

export type ConfigMaps = {
  [key: string]: TypeMicrocopy['fields'];
};

export const getConfigMaps = (microconfigSets?: (TypeMicrocopySet | undefined)[]): ConfigMaps[] => {
  return (
    microconfigSets
      ?.filter((set): set is TypeMicrocopySet => !!set && isTypeMicrocopySet(set))
      .map((set) => {
        const configMap = set.fields.resources
          .concat(set.fields.settings || [])
          .reduce<ConfigMaps>((map, item) => {
            if (item) map[item.fields.key] = item.fields;
            return map;
          }, {});
        return configMap;
      }) || []
  );
};

const MicroconfigTemplate = ({
  slug,
  serverSideProps,
  microconfigSetTemplate,
}: {
  slug: string;
  serverSideProps: Record<string, unknown>;
  microconfigSetTemplate: TypeLandingPage['fields']['microconfigSetTemplate'];
}) => {
  if (!microconfigSetTemplate) {
    return null;
  }
  const MicroconfigSetTemplatePage =
    MICROCONFIGSET_TEMPLATE_PAGE_MAP_RENDER[microconfigSetTemplate];
  return <MicroconfigSetTemplatePage slug={slug} serverSideProps={serverSideProps} />;
};

export default MicroconfigTemplate;
