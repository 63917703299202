import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LayoutProps } from '../../../types/LayoutTypes';
import { EVENT_NAMES, sendEvent } from '../../../utils/analytics';
import { getEntries } from '../../../utils/api/contentful';
import {
  formatMicrocopy,
  GlobalKeys,
  MicrocopyContext,
  microcopyGlobalKeys,
  microcopySet,
  MicrocopySets,
} from '../../../utils/contentfulMicrocopy';
import { PageContext } from '../../../utils/helpers/next';
import useLocale from '../../../utils/hooks/useLocale';
import { WithPageAppProps } from '../../../utils/withAppProps';
import { Container } from '../../component-library/layout/12ColGridLayout';
import PageSection from '../PageSection';
import { PaymentFlowTrackingContext } from '../contexts/PaymentFlowTrackingContext';
import { UnknownServerSideProps } from '../pageSections';
import MicroconfigTemplate, { getConfigMaps } from './MicroconfigTemplate';
import loadServerSideProps from './loadServerSideProps';
import { transformMetaHeaderType } from '../MetaHeader';
import { Overrides } from '../pages/template';
import {
  TypeLandingPage,
  TypeMicrocopySetSkeleton,
  TypePageSection,
} from '../../../../@types/generated';

/**
 * If you are unfamiliar with template pages
 * Check contentful [readme](../readme.md)
 */

export type TemplatePageProps<T = unknown> = {
  pageId: string;
  slug: string;
  sections: TypePageSection[];
  serverSideProps: UnknownServerSideProps[] | undefined;
  injectedServerSideProps?: T;
  layoutProps: LayoutProps | undefined;
  microcopy: MicrocopySets | undefined;
  microconfigSetTemplate: TypeLandingPage['fields']['microconfigSetTemplate'];
  categoryName: string | undefined;
};

function prunePageSection(
  sections: TypePageSection[],
  serverSideProps: UnknownServerSideProps[],
): TypePageSection[] {
  return sections.map((section, index) =>
    !serverSideProps?.[index]?.ssrProcessed
      ? section
      : {
          ...section,
          fields: {
            identifier: section.fields.identifier,
            type: section.fields.type,
          },
        },
  );
}

export const getTemplatePage = <T,>({
  campaignName,
  floatingHeader,
  Component,
}: {
  campaignName?: string;
  floatingHeader?: boolean;
  Component?: ({ serverSideProps }: { serverSideProps: T }) => JSX.Element;
} = {}) => {
  const TemplatePage: NextPage<TemplatePageProps<T> & { disableMaxWidth?: boolean }> & {
    layoutProps?: LayoutProps;
  } = ({
    sections,
    serverSideProps,
    injectedServerSideProps,
    disableMaxWidth,
    microcopy,
    slug,
    pageId,
    microconfigSetTemplate,
    categoryName,
  }) => {
    const router = useRouter();
    const locale = useLocale();
    const { utm_source, utm_content, utm_referrer, utm_campaign, utm_medium, country } =
      router.query;
    const paymentFlowId = useRef(uuidv4());

    useEffect(() => {
      if (campaignName) {
        sendEvent({
          name: EVENT_NAMES.viewCampaign,
          properties: {
            'campaign name': campaignName,
            categoryId: microconfigSetTemplate
              ? (serverSideProps?.[0]?.categoryId as string)
              : undefined,
            slug,
            'payment flow id': paymentFlowId.current,
            referrer: document.referrer,
            country,
            language: locale,
            'utm source': utm_source,
            'utm content': utm_content,
            'utm referrer': utm_referrer,
            'utm campaign': utm_campaign,
            'utm medium': utm_medium,
          },
        });
      }
    }, [
      utm_source,
      utm_content,
      utm_referrer,
      utm_campaign,
      utm_medium,
      slug,
      locale,
      country,
      microconfigSetTemplate,
      serverSideProps,
    ]);

    return (
      <MicrocopyContext.Provider value={microcopy}>
        <PaymentFlowTrackingContext.Provider
          value={{ paymentFlowId: paymentFlowId.current, contentfulLandingPageId: pageId }}
        >
          <Container disableMaxWidth={disableMaxWidth}>
            {sections?.map((section: TypePageSection, index) => (
              <PageSection
                index={index}
                slug={slug}
                categoryName={categoryName}
                section={section}
                key={section.sys.id}
                serverSideProps={serverSideProps?.[index] || {}}
                floatingHeader={floatingHeader}
              />
            ))}
            {microconfigSetTemplate && (
              <MicroconfigTemplate
                slug={slug}
                microconfigSetTemplate={microconfigSetTemplate}
                serverSideProps={serverSideProps?.[0] || {}}
              ></MicroconfigTemplate>
            )}
            {Component && injectedServerSideProps && (
              <Component serverSideProps={injectedServerSideProps} />
            )}
          </Container>
        </PaymentFlowTrackingContext.Provider>
      </MicrocopyContext.Provider>
    );
  };
  return TemplatePage;
};

/*
 * If the first section of a page is one of the specified
 * types, we render the page with a transparent navbar.
 */
const TOP_SECTION_TYPES = ['hero-donation', 'hero-standard', 'ppc-hero'];

function shouldRenderPageWithTransparentNavbar(page: TypeLandingPage) {
  return TOP_SECTION_TYPES.includes(page?.fields?.sections?.[0]?.fields?.type ?? '');
}

export const getServerSideProps = async <T,>({
  page,
  locale,
  context,
  overrides,
  injectedServerSideProps,
}: {
  page: TypeLandingPage;
  locale?: string;
  context: PageContext;
  overrides?: Overrides;
  injectedServerSideProps?: T;
}): Promise<
  {
    props: TemplatePageProps<T>;
  } & WithPageAppProps
> => {
  const serverSideProps = await loadServerSideProps({
    page,
    locale,
    context,
  });
  const pageSections =
    page.fields.sections && serverSideProps
      ? prunePageSection(page.fields.sections as TypePageSection[], serverSideProps)
      : [];
  const microcopySetGlobalKeys: GlobalKeys = [...microcopyGlobalKeys];
  if (page.fields.paymentOption) {
    microcopySetGlobalKeys.push(microcopySet.PAYMENT_OPTION);
  }

  const configMaps = page.fields.microconfigSets ? getConfigMaps(page.fields.microconfigSets) : [];

  const getMicrocopy = async () => {
    const entries = await getEntries<TypeMicrocopySetSkeleton>({
      content_type: 'microcopySet',
      locale,
      'fields.name[in]': microcopySetGlobalKeys,
    });
    return formatMicrocopy(entries);
  };

  const microcopy = await getMicrocopy();

  const hasTransparentHeader =
    page.fields.microconfigSetTemplate === 'climate-transformation-fund' || // TODO: Add to shouldRenderPageWithTransparentNavbar check when converting CTF page to proper page sections
    shouldRenderPageWithTransparentNavbar(page);
  const isGiveOnePage = page.fields.tags?.includes('Give one');
  const disableTrackingConsent = page.fields.disableTrackingConsent || isGiveOnePage;
  const metaHeader = page.fields.metaHeader;
  const navigationBar = page.fields.navigationBar
    ? {
        ...page.fields.navigationBar,
        fields: {
          ...page.fields.navigationBar.fields,
          ...overrides?.navigationBar,
        },
      }
    : undefined;

  return {
    props: {
      pageId: page.sys.id,
      slug: page.fields.slug,
      sections: pageSections,
      microconfigSetTemplate: page.fields.microconfigSetTemplate,
      microcopy,
      serverSideProps: serverSideProps?.map((ssp) => ({ ...ssp, microConfig: configMaps[0] })),
      injectedServerSideProps: injectedServerSideProps
        ? {
            ...injectedServerSideProps,
            microConfig: configMaps[0],
          }
        : undefined,
      layoutProps: {
        initiallyTransparentHeader: hasTransparentHeader,
        floatingHeader: hasTransparentHeader,
      },
      categoryName: page.fields.category?.fields.name,
    },
    pageAppProps: {
      isPPCPage: page.fields.slug?.includes('pricelessplanet'),
      metaHeader: transformMetaHeaderType({
        metaHeader,
        overrides: overrides?.metaHeader,
      }),
      navigationBar,
      footerBar: page.fields.footer,
      disableTrackingConsent,
    },
  };
};
