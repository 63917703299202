import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import { TypeLandingPage, TypePageSection } from '../../../@types/generated';
import { PageContext } from '../../utils/helpers/next';
import './Image';
import './RichText/RichText';

const HeroGraphicSection = dynamic(() => import('./HeroGraphicSection'));
const HeroStandardSection = dynamic(() => import('./HeroStandardSection'));
const HeroDonationSection = dynamic(() => import('./HeroDonationSection'));
const HeadlinerSection = dynamic(() => import('./HeadlinerSection'));
const CausesSection = dynamic(() => import('./CausesSection'));
const PartnerLogosSection = dynamic(() => import('./PartnerLogosSection'));
const PPCMapSection = dynamic(() => import('./PPCMapSection'));
const HorizontalListSection = dynamic(() => import('./HorizontalListSection'));
const ImpactersSection = dynamic(() => import('./ImpactersSection'));
const CampaignsSection = dynamic(() => import('./CampaignsSection'));
const HighlightSectionBleed = dynamic(() => import('./HighlightSectionBleed'));
const BigParagraphSection = dynamic(() => import('./BigParagraphSection'));
const HighlightSection = dynamic(() => import('./HighlightSection'));
const QuotesSection = dynamic(() => import('./QuotesSection'));
const FormSection = dynamic(() => import('./FormSection'));
const SmallListSection = dynamic(() => import('./SmallListSection'));
const FAQSection = dynamic(() => import('./QuestionsAndAnswersSection'));
const CTAPush = dynamic(() => import('./CTAPush'));
const PPCHeroSection = dynamic(() => import('./PPCHeroSection'));
const PPCDonationSection = dynamic(() => import('./PPCDonationSection'));
const PPCTermsAndConditions = dynamic(() => import('./PPCTermsAndConditions'));
const PPCContentUpdatesSection = dynamic(() => import('./PPCContentUpdatesSection'));
const TwoColumnSection = dynamic(() => import('./TwoColumnSection'));

export type UniversalSectionProps = {
  section: TypePageSection;
  index?: number;
  floatingHeader?: boolean;
  locale?: string;
};

export type ClientSectionProps = {
  slug: string;
  categoryName?: string;
} & UniversalSectionProps;

export type ServerSectionProps = {
  page: TypeLandingPage;
  context: PageContext;
} & UniversalSectionProps;

export type UnknownServerSideProps = Record<string, unknown> | null;

/**
 * has an issue with react-docgen-typescript => https://github.com/styleguidist/react-docgen-typescript/issues/342
 */
export type PageSectionComponent<T extends UnknownServerSideProps> = FunctionComponent<
  ClientSectionProps & {
    serverSideProps: T;
  }
> & {
  getCustomServerSideProps?: (props: ServerSectionProps) => Promise<T>;
};

export type PageSectionComponentClientOnly = FunctionComponent<ClientSectionProps>;

export const SECTION_MAP_SERVER = {
  'big-paragraph': () => import('./BigParagraphSection'),
  campaigns: () => import('./CampaignsSection'),
  'cause-pages': () => import('./CausesSection'),
  'cta-push': () => import('./CTAPush'),
  form: () => import('./FormSection'),
  headliner: () => import('./HeadlinerSection'),
  'hero-donation': () => import('./HeroDonationSection'),
  'hero-graphic': () => import('./HeroGraphicSection'),
  'hero-standard': () => import('./HeroStandardSection'),
  highlight: () => import('./HighlightSection'),
  'highlight-bleed': () => import('./HighlightSectionBleed'),
  'horizontal-list': () => import('./HorizontalListSection'),
  impacters: () => import('./ImpactersSection'),
  map: () => import('./PPCMapSection'),
  'partner-logos': () => import('./PartnerLogosSection'),
  'ppc-map': () => import('./PPCMapSection'),
  'questions-and-answers': () => import('./QuestionsAndAnswersSection'),
  quotes: () => import('./QuotesSection'),
  'small-list': () => import('./SmallListSection'),
  'ppc-hero': () => import('./PPCHeroSection'),
  'ppc-donation': () => import('./PPCDonationSection'),
  'two-column-section': () => import('./TwoColumnSection'),
  'ppc-terms-and-conditions': () => import('./PPCTermsAndConditions'),
  'ppc-content-updates': () => import('./PPCContentUpdatesSection'),
} as Record<string, () => Promise<{ default: PageSectionComponent<UnknownServerSideProps> }>>;

export const SECTION_MAP_RENDER = {
  'big-paragraph': BigParagraphSection,
  campaigns: CampaignsSection,
  'cause-pages': CausesSection,
  'cta-push': CTAPush,
  form: FormSection,
  headliner: HeadlinerSection,
  'hero-donation': HeroDonationSection,
  'hero-graphic': HeroGraphicSection,
  'hero-standard': HeroStandardSection,
  highlight: HighlightSection,
  'highlight-bleed': HighlightSectionBleed,
  'horizontal-list': HorizontalListSection,
  impacters: ImpactersSection,
  map: PPCMapSection,
  'partner-logos': PartnerLogosSection,
  'ppc-map': PPCMapSection,
  'questions-and-answers': FAQSection,
  quotes: QuotesSection,
  'small-list': SmallListSection,
  'ppc-hero': PPCHeroSection,
  'ppc-donation': PPCDonationSection,
  'two-column': TwoColumnSection,
  'ppc-terms-and-conditions': PPCTermsAndConditions,
  'ppc-content-updates': PPCContentUpdatesSection,
} as Record<string, PageSectionComponent<UnknownServerSideProps>>;
