import { TypeLandingPage, TypeLink, TypePageSection } from '../../../../@types/generated';
import { isTypeBuildingBlock } from '../../../../@types/generated/TypeBuildingBlock';
import { isTypeIllustratedText } from '../../../../@types/generated/TypeIllustratedText';
import {
  isTypeLandingPage,
  TypeLandingPageSkeleton,
} from '../../../../@types/generated/TypeLandingPage';
import { isTypePageSection } from '../../../../@types/generated/TypePageSection';
import { getEntry } from '../../../utils/api/contentful';
import { isUrlError } from '../../../utils/error';
import { PageContext } from '../../../utils/helpers/next';
import logger from '../../../utils/log';
import { captureException } from '../../../utils/tracking/sentry/sentry';
import { SECTION_MAP_SERVER } from '../pageSections';
import { getConfigMaps, MICROCONFIG_TEMPLATE_PAGE_MAP_SERVER } from './MicroconfigTemplate';

const loadServerSideProps = async ({
  page,
  context,
  locale,
}: {
  page: TypeLandingPage;
  context: PageContext;
  locale?: string;
}) => {
  if (page.fields.microconfigSetTemplate) {
    return await Promise.all([loadMicroconfigSetServerSide({ page, locale, context })]);
  }
  if (!page.fields.sections) {
    return;
  }
  return await Promise.all(
    page.fields.sections
      .filter((section): section is TypePageSection => !!section && isTypePageSection(section))
      .map((section) => loadSectionServerSide({ page, locale, section, context })),
  );
};

const loadSectionServerSide = async ({
  page,
  section,
  locale,
  context,
}: {
  page: TypeLandingPage;
  section: TypePageSection;
  locale?: string;
  context: PageContext;
}) => {
  try {
    const loader = section.fields.type && SECTION_MAP_SERVER[section.fields.type];
    if (!loader) {
      return null;
    }
    const { default: Component } = await loader();
    await loadLvl5References({ section });
    if (!Component || !Component.getCustomServerSideProps) {
      return null;
    }
    return await Component.getCustomServerSideProps({ page, section, locale, context });
  } catch (err) {
    captureException(err);
    logger.error(
      { err, slug: page.fields.slug, type: section.fields.type },
      'Failed to load component',
    );
    return null;
  }
};

const loadLvl5References = async ({ section }: { section: TypePageSection }) => {
  await Promise.all(
    (section.fields.references || []).map(async (ref) => {
      if (
        ((ref && isTypeBuildingBlock(ref)) || (ref && isTypeIllustratedText(ref))) &&
        ref.fields.button?.fields.link
      ) {
        await fetchAndUpdateLinkInternalRef({ link: ref.fields.button.fields.link });
      }
    }),
  );
  if (section.fields.buildingBlock?.fields.button?.fields.link) {
    await fetchAndUpdateLinkInternalRef({
      link: section.fields.buildingBlock?.fields.button?.fields.link,
    });
  }
};

/**
 * button links are 4 level deep
 */
const fetchAndUpdateLinkInternalRef = async ({ link }: { link?: TypeLink }) => {
  if (!link) return;

  const ref = link.fields.internalRef;
  if (!ref) {
    return;
  }
  const entryOnlySys = await getEntry(ref.sys.id);
  if (!isTypeLandingPage(entryOnlySys) && !isTypePageSection(entryOnlySys)) {
    return;
  }
  ref.sys = entryOnlySys.sys;
  if (!isTypeLandingPage(entryOnlySys)) {
    return;
  }
  const landingPageWithLinkingFields = await getEntry<TypeLandingPageSkeleton>(ref.sys.id);
  ref.fields = landingPageWithLinkingFields.fields;
};

const loadMicroconfigSetServerSide = async ({
  page,
  context,
  locale,
}: {
  page: TypeLandingPage;
  context?: PageContext;
  locale?: string;
}) => {
  const { microconfigSets, microconfigSetTemplate } = page.fields;
  if (!microconfigSetTemplate) {
    return null;
  }
  try {
    const loader = await MICROCONFIG_TEMPLATE_PAGE_MAP_SERVER[microconfigSetTemplate];
    if (!loader) {
      return null;
    }
    const { default: Component } = await loader();
    if (!Component || !Component.getCustomServerSideProps) {
      return null;
    }
    return await Component.getCustomServerSideProps({
      page,
      configMaps: getConfigMaps(microconfigSets),
      context,
      locale,
    });
  } catch (err) {
    if (isUrlError(err)) throw err;
    captureException(err);
    logger.error(
      {
        err,
        slug: page.fields.slug,
        type: microconfigSetTemplate,
        pageSysId: page.sys.id,
      },
      'Failed to load microconfig',
    );
    return null;
  }
};

export default loadServerSideProps;
